.job-show-payments{
    .payment_table_header{
        .payment_table_item:nth-of-type(4){
            text-align:right;
        }
    }
    .payment_table_line{
        .payment_table_item:nth-of-type(4){
            text-align:right;
        }
    }
}

.crowded_action{
    margin-bottom:20px;
}