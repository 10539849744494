.webview_choices{
    margin-top:40px;
    margin-bottom:40px;
    a{
        border-radius:2px;
        text-decoration:none;
        color:white;
        padding: 15px 45px;
        display:inlne-block;
    }
    &__column{
        display:flex;
        align-items:center;
        &:nth-of-type(1){
            justify-content:flex-end;
        }
    }
}

.accept_b{
    background-color: #5cb85c;
    border: 1px solid #4cae4c;
}

.deny_b{
    background-color: #d9534f;
    border: 1px solid #d43f3a;
}

@media (max-width: 767px){
    .webview_choices__column:nth-of-type(1){
        justify-content:center;
    }
    .webview_choices__column:nth-of-type(2){
        justify-content:center;
    }
    .webview_choices{
        a{
            width:100%;
            margin-bottom:20px;
            text-align:center;
        }
    }
}