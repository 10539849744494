.paid-success-wrapper{
    .success-info{
        p{
            text-align:center;
        }
    }
    .price{
        font-weight:bold;
        text-align:center;
    }
    h5{
        text-align:center;
    }
}