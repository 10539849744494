form#generator{
    display:grid;
    grid-template-columns:1fr;
    .generator_input_group{
        display:flex;
        flex-direction:column;
        padding:20px;
        input{
            height:32px;
            padding-left:10px;
        }
    }
}

.generator_flex_group{
    display:flex;
    flex-direction:column;
    padding:20px;
    width:100%;
}

.generator_submit{
    margin-top:20px;
    padding-left:20px;
    padding-right:20px;
    span{
        background:#6774c8;
        padding:15px 25px;
        border-radius:6px;
        color:white;
        cursor:pointer;
    }
}

.generator_grid{
    display:grid;
    grid-template-columns:1fr 1fr;
}
.gen_totals{
    padding:20px;
}
@media (max-width: 767px){
    .generator_grid{
        display:grid;
        grid-template-columns:1fr;
    }
    .gen_totals{
        margin-top:40px;
    }
}