.payment_table_header{
    display:flex;
    border-bottom:1px solid #eee;
    padding-bottom:10px;
    margin-top:25px;
    .payment_table_item{
        flex:1;
        font-weight:bold;
    }
}

.payment_table_line{
    display:flex;
    padding-top:10px;
    .payment_table_item{
        flex:1;
    }
}
.refund_button_wrap{
    display:flex;
    width:100%;
    justify-content:flex-end;
    padding-top:10px;
}

.payment_wrap{
    margin-bottom:40px;
}
.ach_container{
    padding-top:5px;
}
.invoice-show-payments{
    .payment_table_header{
        .payment_table_item:nth-of-type(4){
            text-align:right;
        }
    }
    .payment_table_line{
        .payment_table_item:nth-of-type(4){
            text-align:right;
        }
    }
}

.service-group{
    margin-bottom:40px;
}