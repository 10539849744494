#job-pending-modal{
    .row-2{
        position:relative;
        a{
            height:100%;
        }
    }
    .button_wrap{
        display:flex;
        flex-direction:row;
        overflow:auto;
    }

    .cancel_job{
        select{
            margin-bottom:10px;
        }
        form{
            padding-top:15px;
            border-top:1px solid lightgrey;
            margin-top:10px;
        }
    }
    .deposit_wrap{
        input{
            margin-bottom:10px;
        }
        form{
            padding-top:15px;
            border-top:1px solid lightgrey;
            margin-top:10px;
        }
    }
}