.lead_deny_container{
    form{
        max-width: 350px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
        button{
            margin-top:10px;
        }
    }
}
.white-bg-2{
    background:white;
    padding-bottom:20px;
}

#deny_group{
    label{
        font-size:12px;
    }
}
#deny-all-leads-modal{
    form{
        padding:15px;
        select{
            margin-bottom:10px;
        }
    }
}
#deny-lead-modal{
    form{
        margin-top:20px;
    }
}

.unable_quote{
    select{
        margin-bottom:10px;
    }
    form{
        padding-top:15px;
        border-top:1px solid lightgrey;
        margin-top:10px;
    }
}